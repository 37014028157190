import React, { useEffect, useState } from "react";

import styled from "styled-components";
import vars from "variables";

const PageNotFound = () => {
  const [show, setShow] = useState(false);

  const Root = styled.div`
    padding: 200px 0 250px;
    text-align: center;

    strong {
      color: ${vars.colors.blue};
      display: block;
      text-transform: uppercase;
    }

    ${vars.desktop.mediaQuery} {
      strong {
        padding-bottom: 20px;
        font-size: 1.35rem;
      }

      h3 {
        font-size: 3rem;
        font-weight: 900;
      }
    }

    ${vars.mobile.mediaQuery} {
      h3 {
        font-size: 1rem;
      }
    }
  `;

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return show ? (
    <Root className="body-width">
      <strong>We're sorry ...</strong>
      <h3>
        <span>404</span> The page could not be found.
      </h3>
    </Root>
  ) : null;
};

export default PageNotFound;
